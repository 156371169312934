import { LessonSkillCategoriesEnum, LessonSkillsEnum, SportsEnum } from 'types/generated/client';

/**
 * @note These come from the lesson_skills, lesson_skill_mappings, lesson_skill_display_names tables
 * They will rarely changes, so I'm dumping it here so we don't have to query the db for them (maybe they shouldn't have gone into the DB in the first place)
 */

export const lessonSkillDisplay: {
  displayName: string;
  id: LessonSkillsEnum;
}[] = [
  {
    displayName: 'Communication',
    id: LessonSkillsEnum.Communication,
  },
  {
    displayName: 'Court Positioning',
    id: LessonSkillsEnum.CourtPositioning,
  },
  {
    displayName: 'Dink',
    id: LessonSkillsEnum.Dink,
  },
  {
    displayName: 'Drive',
    id: LessonSkillsEnum.Drive,
  },
  {
    displayName: 'Drop',
    id: LessonSkillsEnum.Drop,
  },
  {
    displayName: 'Foot Work',
    id: LessonSkillsEnum.FootWork,
  },
  {
    displayName: 'Lob',
    id: LessonSkillsEnum.Lob,
  },
  {
    displayName: 'Mental Skills',
    id: LessonSkillsEnum.MentalSkills,
  },
  {
    displayName: 'Reset',
    id: LessonSkillsEnum.Reset,
  },
  {
    displayName: 'Return',
    id: LessonSkillsEnum.Return,
  },
  {
    displayName: 'Serve',
    id: LessonSkillsEnum.Serve,
  },
  {
    displayName: 'Smash',
    id: LessonSkillsEnum.Smash,
  },
  {
    displayName: 'Flick',
    id: LessonSkillsEnum.Flick,
  },
  {
    displayName: 'Strategy and Tactics',
    id: LessonSkillsEnum.StrategyAndTactics,
  },
  {
    displayName: 'Volley',
    id: LessonSkillsEnum.Volley,
  },
  {
    displayName: 'ATP',
    id: LessonSkillsEnum.Atp,
  },
  {
    displayName: 'Ernie',
    id: LessonSkillsEnum.Ernie,
  },
  {
    displayName: 'Forehand',
    id: LessonSkillsEnum.Forehand,
  },
  {
    displayName: 'Backhand',
    id: LessonSkillsEnum.Backhand,
  },
  {
    displayName: 'Inside Out',
    id: LessonSkillsEnum.InsideOut,
  },
  {
    displayName: 'Outside In',
    id: LessonSkillsEnum.OutsideIn,
  },
  {
    displayName: 'Half Volley',
    id: LessonSkillsEnum.HalfVolley,
  },
  {
    displayName: 'Second Serve',
    id: LessonSkillsEnum.SecondServe,
  },
  {
    displayName: 'Slice',
    id: LessonSkillsEnum.Slice,
  },
  {
    displayName: 'Picklr P4 Dinks',
    id: LessonSkillsEnum.P4Dinks,
  },
  {
    displayName: 'Picklr P4 Volleys',
    id: LessonSkillsEnum.P4Volleys,
  },
  {
    displayName: 'Picklr P4 Volley Dinks',
    id: LessonSkillsEnum.P4VolleyDinks,
  },
  {
    displayName: 'Picklr P4 Resets',
    id: LessonSkillsEnum.P4Resets,
  },
  {
    displayName: 'Picklr P4 Drops',
    id: LessonSkillsEnum.P4Drops,
  },
  {
    displayName: 'Picklr P4 Drives',
    id: LessonSkillsEnum.P4Drives,
  },
  {
    displayName: 'Picklr P4 Lobs',
    id: LessonSkillsEnum.P4Lobs,
  },
  {
    displayName: 'Picklr P4 Drips',
    id: LessonSkillsEnum.P4Drips,
  },
  {
    displayName: 'Picklr P4 Serves',
    id: LessonSkillsEnum.P4Serves,
  },
  {
    displayName: 'Picklr P4 Returns',
    id: LessonSkillsEnum.P4Returns,
  },
  {
    displayName: 'Picklr P4 Erne',
    id: LessonSkillsEnum.P4Erne,
  },
  {
    displayName: 'Picklr P4 ATP',
    id: LessonSkillsEnum.P4Atp,
  },
  {
    displayName: 'Picklr P4 Footwork',
    id: LessonSkillsEnum.P4Footwork,
  },
  {
    displayName: 'Picklr P4 Ready Position',
    id: LessonSkillsEnum.P4ReadyPosition,
  },
  {
    displayName: 'Picklr P4 Ball Quads',
    id: LessonSkillsEnum.P4BallQuads,
  },
  {
    displayName: 'Picklr P4 Body Quads',
    id: LessonSkillsEnum.P4BodyQuads,
  },
  {
    displayName: 'Picklr P4 Quabble',
    id: LessonSkillsEnum.P4Quabble,
  },
  {
    displayName: 'Depth Quads',
    id: LessonSkillsEnum.DepthQuads,
  },
  {
    displayName: 'Picklr P4 Mindset',
    id: LessonSkillsEnum.P4Mindset,
  },
  {
    displayName: 'Picklr P4 Shallow Dinks',
    id: LessonSkillsEnum.P4ShallowDinks,
  },
  {
    displayName: 'Picklr P4 Energy Bounce',
    id: LessonSkillsEnum.P4EnergyBounce,
  },
  {
    displayName: 'Picklr P4 Energy Volley',
    id: LessonSkillsEnum.P4EnergyVolley,
  },
  {
    displayName: 'Picklr P4 Energy Transition Quad',
    id: LessonSkillsEnum.P4EnergyTransitionQuad,
  },
  {
    displayName: 'Quatro Dinks',
    id: LessonSkillsEnum.QuatroDinks,
  },
  {
    displayName: 'Quatro Volleys',
    id: LessonSkillsEnum.QuatroVolleys,
  },
  {
    displayName: 'Quatro Volley Dinks',
    id: LessonSkillsEnum.QuatroVolleyDinks,
  },
  {
    displayName: 'Quatro Resets',
    id: LessonSkillsEnum.QuatroResets,
  },
  {
    displayName: 'Quatro Drops',
    id: LessonSkillsEnum.QuatroDrops,
  },
  {
    displayName: 'Quatro Drives',
    id: LessonSkillsEnum.QuatroDrives,
  },
  {
    displayName: 'Quatro Lobs',
    id: LessonSkillsEnum.QuatroLobs,
  },
  {
    displayName: 'Quatro Drips',
    id: LessonSkillsEnum.QuatroDrips,
  },
  {
    displayName: 'Quatro Serves',
    id: LessonSkillsEnum.QuatroServes,
  },
  {
    displayName: 'Quatro Returns',
    id: LessonSkillsEnum.QuatroReturns,
  },
  {
    displayName: 'Quatro Erne',
    id: LessonSkillsEnum.QuatroErne,
  },
  {
    displayName: 'Quatro ATP',
    id: LessonSkillsEnum.QuatroAtp,
  },
  {
    displayName: 'Quatro Footwork',
    id: LessonSkillsEnum.QuatroFootwork,
  },
  {
    displayName: 'Quatro Ready Position',
    id: LessonSkillsEnum.QuatroReadyPosition,
  },
  {
    displayName: 'Quatro Ball Quads',
    id: LessonSkillsEnum.QuatroBallQuads,
  },
  {
    displayName: 'Quatro Body Quads',
    id: LessonSkillsEnum.QuatroBodyQuads,
  },
  {
    displayName: 'Quatro Quabble',
    id: LessonSkillsEnum.QuatroQuabble,
  },
  {
    displayName: 'Directional Quads',
    id: LessonSkillsEnum.DirectionalQuads,
  },
  {
    displayName: 'Kitchen Quad',
    id: LessonSkillsEnum.KitchenQuad,
  },
  {
    displayName: 'Heat Quad',
    id: LessonSkillsEnum.HeatQuad,
  },
  {
    displayName: 'Transition Quad',
    id: LessonSkillsEnum.TransitionQuad,
  },
  {
    displayName: 'Baseline Quad',
    id: LessonSkillsEnum.BaselineQuad,
  },
  {
    displayName: 'Shallow Quad Pro',
    id: LessonSkillsEnum.ShallowQuadPro,
  },
  {
    displayName: 'Spin',
    id: LessonSkillsEnum.Spin,
  },
];

export const lessonSkillMappings: {
  id: string;
  skill: LessonSkillsEnum;
  sport: SportsEnum | null;
  category: LessonSkillCategoriesEnum | null;
}[] = [
  {
    id: '2eb4e5b7-074e-44d3-8df5-95748595ced7',
    skill: LessonSkillsEnum.Communication,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '779d0649-78fd-431e-a970-647f52cf99bc',
    skill: LessonSkillsEnum.CourtPositioning,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '9bac366a-03b2-4c68-b186-055bc73f7fad',
    skill: LessonSkillsEnum.Dink,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'fb267528-44e2-4f89-8737-976489491bed',
    skill: LessonSkillsEnum.Drive,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'b52ec72e-9589-4200-8c36-6f2b90e91723',
    skill: LessonSkillsEnum.Drop,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'e3d94722-8ee6-4686-9c8b-149d48d3198b',
    skill: LessonSkillsEnum.FootWork,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'a1b866bd-1d67-475c-96b4-9e0f6893861b',
    skill: LessonSkillsEnum.Lob,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '8c3cb7c7-11a6-4841-93ac-773ef2965d75',
    skill: LessonSkillsEnum.MentalSkills,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '846acac9-3ee3-4b05-bbc4-0ba4453bec7f',
    skill: LessonSkillsEnum.Reset,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '370ee1a8-6254-4ef3-ade2-6c6fc423a2f5',
    skill: LessonSkillsEnum.Return,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '39d6293b-32f3-49e7-8655-33272d271f4b',
    skill: LessonSkillsEnum.Serve,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'e09463ca-bf8d-483b-9125-9d11bf3156e1',
    skill: LessonSkillsEnum.Smash,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '51d3c8d6-45d3-4fe5-bac3-e5aa7929e556',
    skill: LessonSkillsEnum.Flick,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '3895cee8-3cd8-438c-81c2-66f3bc5b39ef',
    skill: LessonSkillsEnum.StrategyAndTactics,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '3b97aba7-7ead-41f3-960f-daf01221976c',
    skill: LessonSkillsEnum.Volley,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: '79f32615-7819-4ddd-8078-e0557fe0a0fe',
    skill: LessonSkillsEnum.Atp,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'fe8e8bc9-14bb-410e-a48a-6fca74168d86',
    skill: LessonSkillsEnum.Ernie,
    sport: SportsEnum.Pickleball,
    category: null,
  },
  {
    id: 'e900e8a4-1159-474d-ae67-dfdd47589f21',
    skill: LessonSkillsEnum.QuatroDinks,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '24af6381-9d1f-4c49-bf0d-8d1ee75c7235',
    skill: LessonSkillsEnum.QuatroVolleys,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'b7b75153-a080-4ce8-ac15-a1498e66644c',
    skill: LessonSkillsEnum.QuatroVolleyDinks,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '806d3712-ea21-403d-a818-5b464e0b74ce',
    skill: LessonSkillsEnum.QuatroResets,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'eb66c4fd-d510-4d60-856a-b59973b20cd8',
    skill: LessonSkillsEnum.QuatroDrops,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '489c9358-b26d-4ed6-9184-a8cf68f40c95',
    skill: LessonSkillsEnum.QuatroDrives,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '00f4c7ab-bbbe-402c-a32d-bcbf524543c1',
    skill: LessonSkillsEnum.QuatroLobs,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '82499c6d-5fe0-4e19-91ce-4a45a724f81f',
    skill: LessonSkillsEnum.QuatroDrips,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'b0c2b49f-b41e-44ee-875a-769631ae8cd9',
    skill: LessonSkillsEnum.QuatroServes,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'c87e7b8d-a078-4b9b-a6c0-8296c1f577ce',
    skill: LessonSkillsEnum.QuatroReturns,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '2446e31a-5bcd-40a6-a1bd-f70b7a81c90e',
    skill: LessonSkillsEnum.QuatroErne,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '77bf580c-6d72-437c-97c4-5c8cf33a68e7',
    skill: LessonSkillsEnum.QuatroAtp,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '9f9c1d99-fc0a-4761-9937-6cc7c2c2b129',
    skill: LessonSkillsEnum.QuatroFootwork,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '07d19d42-5e84-4fb2-aefc-0bda4296627e',
    skill: LessonSkillsEnum.QuatroReadyPosition,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '7627f566-fc57-4e43-b1a2-8c5e177f4c68',
    skill: LessonSkillsEnum.QuatroBallQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '7095d84b-520c-42fd-b517-a600bd74b22d',
    skill: LessonSkillsEnum.QuatroBodyQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '36fe3cb4-b6c5-434b-b50c-3c92903fd41c',
    skill: LessonSkillsEnum.QuatroQuabble,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '3efc9917-ef8c-41b1-9f65-e8d97f993f47',
    skill: LessonSkillsEnum.DirectionalQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '863e9b1e-3382-4e81-951b-64b3a02b1fa2',
    skill: LessonSkillsEnum.DepthQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '6d433bfd-432f-4f6b-850c-9e39cc9f251f',
    skill: LessonSkillsEnum.QuatroMindset,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'ba9f12e1-a23d-4017-a033-cb2797bdcfff',
    skill: LessonSkillsEnum.QuatroShallowDinks,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'f9272ca4-819b-4af2-936d-c14873831d92',
    skill: LessonSkillsEnum.QuatroEnergyBounce,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '53e868de-4561-442c-a5f3-ee7f010d51b6',
    skill: LessonSkillsEnum.QuatroEnergyVolley,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '3001c0b6-79a6-4aab-876a-d5783223a4bc',
    skill: LessonSkillsEnum.QuatroEnergyTransitionQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '4b4d18de-5d15-4945-8d54-e856390852cd',
    skill: LessonSkillsEnum.KitchenQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: '382bee3a-1e9b-45a2-bd3c-677d2ab82248',
    skill: LessonSkillsEnum.HeatQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'b62f7571-8b3f-4601-b497-8fe09f14bbaa',
    skill: LessonSkillsEnum.TransitionQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'c050b18c-3358-45b2-a0ad-da320eae5ff9',
    skill: LessonSkillsEnum.BaselineQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'c649a216-a59e-42b1-b347-6e1cd636ddfa',
    skill: LessonSkillsEnum.ShallowQuadPro,
    sport: null,
    category: LessonSkillCategoriesEnum.Quatro,
  },
  {
    id: 'ba8a39a3-7dc3-4fef-be9b-bdda54217314',
    skill: LessonSkillsEnum.P4Dinks,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '11d22312-368d-4367-a327-baf85bb145f8',
    skill: LessonSkillsEnum.P4Volleys,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '431f2f4e-06dd-49d0-9301-e1cc4bd7a772',
    skill: LessonSkillsEnum.P4VolleyDinks,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '1afbc949-9933-45e1-a331-3b302b005a87',
    skill: LessonSkillsEnum.P4Resets,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '0782a3c7-dc18-45eb-89f6-5115cc4e6dfb',
    skill: LessonSkillsEnum.P4Drops,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '2ea35007-d399-41cb-a92f-b56675f7eafd',
    skill: LessonSkillsEnum.P4Drives,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'c92088a0-1e2d-4e9c-9c75-e749491737a7',
    skill: LessonSkillsEnum.P4Lobs,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '3a08d250-df99-4896-ab65-775855495dab',
    skill: LessonSkillsEnum.P4Drips,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '957516a2-ceae-4397-9e3d-98af002ebf61',
    skill: LessonSkillsEnum.P4Serves,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '8363c7cd-0119-46ae-948d-12ec1f68c3b5',
    skill: LessonSkillsEnum.P4Returns,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'f735ed4f-d422-47d4-a522-6ba786ec645a',
    skill: LessonSkillsEnum.P4Erne,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'ae6257e3-ee9b-4659-b5bf-5dce6e12a783',
    skill: LessonSkillsEnum.P4Atp,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'e4b6cf59-f7f7-4c37-b0ca-8b4e32a971d4',
    skill: LessonSkillsEnum.P4Footwork,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '70789bbc-43ab-4e29-8e32-3e75e1756242',
    skill: LessonSkillsEnum.P4ReadyPosition,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '5f0dec4f-e1c9-4c40-8cbd-03a8c42fe002',
    skill: LessonSkillsEnum.P4BallQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '8e4c40cc-b362-40e7-b0e0-d82ecdbe7651',
    skill: LessonSkillsEnum.P4BodyQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '144f8e75-c3b5-4988-abb7-e49ea0aba915',
    skill: LessonSkillsEnum.P4Quabble,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'b06786aa-24df-46a0-bcaf-f62e00fad909',
    skill: LessonSkillsEnum.DirectionalQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'f225ad3c-638e-491f-9bfb-d041fcf26a8c',
    skill: LessonSkillsEnum.DepthQuads,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '3bb78d71-491a-47f1-8c6b-3893f8805e7e',
    skill: LessonSkillsEnum.P4Mindset,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'd63330ed-f3f3-4d25-907e-b089ccc5944f',
    skill: LessonSkillsEnum.P4ShallowDinks,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'a6cac75a-8861-4aa8-a7a0-a220fcd24e93',
    skill: LessonSkillsEnum.P4EnergyBounce,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '4a1016de-9596-4502-ba9f-d41940839b33',
    skill: LessonSkillsEnum.P4EnergyVolley,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'f1fad7c8-2b03-4da2-97e4-bf6db1454698',
    skill: LessonSkillsEnum.P4EnergyTransitionQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '309324ee-6a16-4c20-9fe7-d3a2ce0d113f',
    skill: LessonSkillsEnum.KitchenQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'dd9bbc56-f31f-43c8-8b85-afa97a8f6e56',
    skill: LessonSkillsEnum.HeatQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '6d0d6876-b618-4d00-933c-2c7fbcebd24e',
    skill: LessonSkillsEnum.TransitionQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'db08a7e5-a939-43ba-a22a-6d7ac5d44a6a',
    skill: LessonSkillsEnum.BaselineQuad,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: 'a333339b-9019-429c-b58b-618f0850d4a6',
    skill: LessonSkillsEnum.ShallowQuadPro,
    sport: null,
    category: LessonSkillCategoriesEnum.P4,
  },
  {
    id: '633a61ab-56ed-4266-a080-c50b34175ac2',
    skill: LessonSkillsEnum.Communication,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '4875746f-419f-46d5-8e90-9ef267e6555f',
    skill: LessonSkillsEnum.CourtPositioning,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '6c1d34b8-107f-427f-9d39-6274179c12c5',
    skill: LessonSkillsEnum.Forehand,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '59cb7050-f0ce-4b91-bd2f-3e29ed14e354',
    skill: LessonSkillsEnum.Backhand,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '6f89706c-927c-4e0f-82a6-26e2ecee9d0c',
    skill: LessonSkillsEnum.FootWork,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: 'ae70a977-426b-4499-9a4c-ca66993bf9c1',
    skill: LessonSkillsEnum.MentalSkills,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '8ad5ac5c-2200-481d-a2e8-2bfcfd28674d',
    skill: LessonSkillsEnum.InsideOut,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: 'e825b9bb-56b6-4b44-bb46-f639ff1e9c3c',
    skill: LessonSkillsEnum.Return,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '397c3463-5b27-477d-b71a-e1ec3ab47a40',
    skill: LessonSkillsEnum.Serve,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '41b49c62-c457-4e8e-9a3a-23f16a235728',
    skill: LessonSkillsEnum.Smash,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '466bba87-1473-4616-86ba-7a1bd8fe2cbe',
    skill: LessonSkillsEnum.OutsideIn,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '4d4c2bd4-3f2a-4393-8e07-a7fe2c54dc70',
    skill: LessonSkillsEnum.StrategyAndTactics,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: 'b80954d1-5704-42b9-921f-8c1c40c532bc',
    skill: LessonSkillsEnum.Volley,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '046b5f64-6940-4646-9b53-e26f1abb4116',
    skill: LessonSkillsEnum.HalfVolley,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: '0eb51d72-6a0a-4078-a12e-0cf70d5d1559',
    skill: LessonSkillsEnum.SecondServe,
    sport: SportsEnum.Tennis,
    category: null,
  },
  {
    id: 'e763b6c4-b5f6-4600-ae53-79ed0760ce3e',
    skill: LessonSkillsEnum.Slice,
    sport: SportsEnum.Tennis,
    category: null,
  },
];

export const pickleballSkills = lessonSkillMappings
  .filter((mapping) => mapping.sport === SportsEnum.Pickleball)
  .map((mapping) => mapping.skill);

export const tennisSkills = lessonSkillMappings
  .filter((mapping) => mapping.sport === SportsEnum.Tennis)
  .map((mapping) => mapping.skill);

export const picklrP4Skills = lessonSkillMappings
  .filter((mapping) => mapping.category === LessonSkillCategoriesEnum.P4)
  .map((mapping) => mapping.skill);

export const quatroSkills = lessonSkillMappings
  .filter((mapping) => mapping.category === LessonSkillCategoriesEnum.Quatro)
  .map((mapping) => mapping.skill);
