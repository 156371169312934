import * as Sentry from '@sentry/nextjs';
import toast from 'react-hot-toast';
import {
  FILE_SIGN_API,
  PostRequestPayload,
  PostResponsePayload,
} from 'constants/payloads/files/sign';
import { uploadSignedFile as uploadSignedFileCloudflare } from 'services/client/cloudflare/uploadSignedFile';
import { useApiGateway } from 'hooks/useApi';
import { useRequestStatus } from 'hooks/useRequestStatus';

export const useUploadSignedFile = () => {
  const {
    isLoading: isLoadingUpload,
    error: errorUpload,
    setLoading: setLoadingUpload,
    setError: setErrorUpload,
    setSuccess: setSuccessUpload,
  } = useRequestStatus();
  const {
    post: postSignFile,
    isLoading: isLoadingSignFile,
    data: dataSignFile,
    error: errorSignFile,
    resetInitialState: resetSignFile,
  } = useApiGateway<PostRequestPayload, PostResponsePayload>(FILE_SIGN_API);

  const uploadSignedFile = async ({ file }: { file: File }) => {
    if (!file) {
      return;
    }

    try {
      const fileName = file.name;
      const fileType = file.type;
      const fileResponse = await postSignFile({
        payload: { fileName, fileType },
      });
      const payload = fileResponse?.data;

      if (typeof payload === 'object') {
        await uploadSignedFileCloudflare({
          file,
          signedUrl: payload.signedUrl,
        });

        resetSignFile();

        return payload;
      }

      resetSignFile();

      return;
    } catch (error) {
      Sentry.captureException(error);
      console.log('ERROR', error);
      setErrorUpload('There was an error, please try again');

      throw error;
    }
  };

  return { uploadSignedFile, isLoadingSignFile, isLoadingUpload, errorSignFile, errorUpload };
};
