import { useRouter } from 'next/router';
import { toTitleCase } from 'utils/shared/toTitleCase';
import { useViewer } from 'hooks/useViewer';
import ReviewCoachNotificationTemplate from './ReviewCoachNotificationTemplate';
import { ReviewLessonNotificationProps } from '../types';

const ReviewLessonNotification: React.FC<ReviewLessonNotificationProps> = ({
  status,
  notification,
}) => {
  const router = useRouter();

  const { query, pathname } = router;

  const viewer = useViewer();

  const primaryEntity = notification?.notificationDetails?.primaryEntity;

  const coachName = toTitleCase(primaryEntity.actingUserProfile?.fullName || '');

  const date = primaryEntity.videoAnalysis?.createdAt || primaryEntity.lesson?.endDateTime;

  const queryParams = {
    ...query,
    coachId: primaryEntity.actingUserProfile?.id,
    playerId: viewer.userId,
    review: 'true',
    ...(primaryEntity.lesson?.id && { lessonId: primaryEntity.lesson?.id }),
    ...(primaryEntity?.videoAnalysis?.id && { videoAnalysisId: primaryEntity.videoAnalysis?.id }),
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const href = `${pathname}?${queryString}`;

  const title = primaryEntity.videoAnalysis?.id ? 'Video Analysis' : 'Lesson';

  return (
    <ReviewCoachNotificationTemplate
      coachName={coachName}
      title={title}
      status={status}
      date={date}
      badgeComponent={
        <div className="rounded-xl bg-color-brand-primary px-2 text-xs leading-5 text-color-text-lightmode-invert">
          Leave a review
        </div>
      }
      href={href}
    />
  );
};

export default ReviewLessonNotification;
