/**
 * uploads file to cloudlfare r2 bucket
 * @returns http status
 */
export const uploadSignedFile = async ({ file, signedUrl }: { file: File; signedUrl: string }) => {
  const res = await fetch(signedUrl, {
    method: 'PUT',
    headers: [
      ['Content-Length', `${file.size}`],
      ['Content-Type', file.type],
    ],
    body: file,
  });

  return res.status;
};
