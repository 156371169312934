import React, { useEffect, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { MediaProviders } from 'constants/media';
import { QUICK_ONBOARD_QUERY_PARAM } from 'constants/pages';
import {
  GenderEnum,
  SportsEnum,
  useGetCoachSettingsQuery,
  useUpdateCoachSettingsQuickMutation,
} from 'types/generated/client';
import { trackEvent } from 'services/client/analytics';
import { ONBOARDING_DEMO_WATCHED } from 'services/client/analytics/events';
import { generateRandomFileName } from 'utils/shared/media/generateRandomFileName';
import { convertFormattedPriceToUnitAmount } from 'utils/shared/money/convertFormattedPriceToUnitAmount';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { toDigits } from 'utils/shared/phone/toDigits';
import { useModal } from 'hooks/useModal';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadSignedFile } from 'hooks/useUploadSignedFile';
import { useViewer } from 'hooks/useViewer';
import CloseIcon from 'svg/CloseIcon';
import Button from 'components/Button';
import Dropzone from 'components/Dropzone';
import InputField from 'components/coaches-builder/InputField';
import { InputType } from 'components/coaches-builder/InputField/InputField';
import RadioList from 'components/forms/RadioList';
import Select from 'components/forms/Select';
import Switcher from 'components/forms/Switcher/Switcher';
import Modal from 'components/modals/Modal';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

const photoSchema = z.object({
  profileImageFileName: z.string(),
  profileImagePath: z.string(),
  profileImageProvider: z.string(),
  profileImageProviderId: z.string(),
  profileImageProviderUrl: z.string().min(1, 'Photo is required'),
  profileImageUrl: z.string(),
});

const quickSettingsSchema = z.object({
  profilePhoto: photoSchema,
  remoteCoaching: z.boolean().optional(),
  onCourtCoaching: z.boolean().optional(),
  priceUnitAmountRemoteCoachDefault: z.number().max(10000).min(0).optional(),
  priceUnitAmountCoachDefault: z.number().max(10000).min(0).optional(),
  gender: z.enum([GenderEnum.Male, GenderEnum.Female]),
  phoneNumber: z
    .string()
    .refine((val) => val.replace(/\D/g, '').length >= 8 && val.replace(/\D/g, '').length <= 15, {
      message: 'Phone number must be between 8 and 15 digits including the country code.',
    }),
  phoneCountryCode: z.string().optional(),
  defaultSport: z.string().min(1, { message: 'Sport is required' }),
});

export default function ModalCoachQuickOnboard() {
  const { isOpen, closeModal, openModal } = useModal();
  const router = useRouter();
  const closeAndResetQuery = () => {
    closeModal();
    router.replace(router.asPath.split('?')[0], undefined, { shallow: true });
  };
  const {
    isLoading: isImageLoading,
    setLoading: setImageLoading,
    setSuccess: setImageSuccess,
    setError: setImageError,
  } = useRequestStatus();
  const [updateCoachSettingsQuickMutation, { loading: isUpdateCoachSettingsQuick }] =
    useUpdateCoachSettingsQuickMutation();
  const viewer = useViewer();
  const photoRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  const sportRef = useRef<HTMLDivElement>(null);
  const { data: coachSettings, loading: isLoadingCoachSettings } = useGetCoachSettingsQuery({
    variables: {
      id: viewer?.userId || '',
    },
    skip: !viewer?.userId,
    fetchPolicy: 'network-only',
  });

  const isDisabled = isUpdateCoachSettingsQuick || isImageLoading || isLoadingCoachSettings;

  const {
    control,
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof quickSettingsSchema>>({
    resolver: zodResolver(quickSettingsSchema),
    defaultValues: {
      remoteCoaching: true,
      priceUnitAmountRemoteCoachDefault: undefined,
      onCourtCoaching: true,
      priceUnitAmountCoachDefault: undefined,
      gender: GenderEnum.Male,
      phoneNumber: '',
      phoneCountryCode: '',
      defaultSport: '',
    },
  });

  const [isVideoStep, setIsVideoStep] = useState(true);

  const { uploadSignedFile, isLoadingSignFile, isLoadingUpload, errorSignFile, errorUpload } =
    useUploadSignedFile();

  const playerRef = useRef<any>(null);
  const [startTime, setStartTime] = useState<number>(0);

  useEffect(() => {
    // Load YouTube API
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    // Initialize player when API is ready
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('demo-video', {
        events: {
          onStateChange: (event: any) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              setStartTime(Date.now());
            } else if (
              event.data === window.YT.PlayerState.PAUSED ||
              event.data === window.YT.PlayerState.ENDED
            ) {
              const watchTime = (Date.now() - startTime) / 1000; // convert to seconds
              trackEvent(ONBOARDING_DEMO_WATCHED, {
                source: 'quick_onboard_modal',
                watchTimeSeconds: watchTime,
                videoComplete: event.data === window.YT.PlayerState.ENDED,
              });
            }
          },
        },
      });
    };
  }, []);

  useEffect(() => {
    if (router.query[QUICK_ONBOARD_QUERY_PARAM] === 'true') {
      openModal();
    }
  }, [router.query]);

  useEffect(() => {
    if (coachSettings && coachSettings.usersByPk) {
      const user = coachSettings.usersByPk;

      if (user.profileImageProviderUrl) {
        setValue('profilePhoto', {
          profileImageFileName: user?.profileImageFileName,
          profileImagePath: user?.profileImagePath,
          profileImageProvider: user?.profileImageProvider,
          profileImageProviderId: user?.profileImageProviderId,
          profileImageProviderUrl: user?.profileImageProviderUrl,
          profileImageUrl: user?.profileImageProviderUrl,
        });
      }

      if (user.priceUnitAmountRemoteCoachDefault) {
        setValue(
          'priceUnitAmountRemoteCoachDefault',
          convertUnitPriceToFormattedPrice(user?.priceUnitAmountRemoteCoachDefault).priceFormatted,
        );
      }

      if (user.priceUnitAmountCoachDefault) {
        setValue(
          'priceUnitAmountCoachDefault',
          convertUnitPriceToFormattedPrice(user?.priceUnitAmountCoachDefault).priceFormatted,
        );
      }

      if (user.gender === GenderEnum.Male || user.gender === GenderEnum.Female) {
        setValue('gender', user.gender);
      }

      setValue('phoneNumber', `${user?.phoneCountryCode}${user?.phoneNumber}` || '');
      setValue('phoneCountryCode', user.phoneCountryCode || '');
      setValue('defaultSport', user.defaultSport || '');
    }
  }, [coachSettings, setValue]);

  const remoteCoaching = watch('remoteCoaching');
  const onCourtCoaching = watch('onCourtCoaching');

  useEffect(() => {
    if (!remoteCoaching) {
      setValue('priceUnitAmountRemoteCoachDefault', 0);
    }
  }, [remoteCoaching, setValue]);

  useEffect(() => {
    if (!onCourtCoaching) {
      setValue('priceUnitAmountCoachDefault', 0);
    }
  }, [onCourtCoaching, setValue]);

  if (isVideoStep) {
    return (
      <Modal
        isOpen={isOpen}
        handleClose={isDisabled ? () => {} : closeAndResetQuery}
        classNameMaxWidth="max-w-2xl"
      >
        <div className="relative flex max-h-[96vh] flex-col">
          <div className="flex items-center justify-between border-b border-color-border-input-lightmode px-4 py-5 dark:border-color-border-input-darkmode md:px-10">
            <h3 className="text-xl font-bold text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              Welcome to Bounce!
            </h3>
            <button
              onClick={() => closeAndResetQuery()}
              className="rounded-full p-2 text-color-text-lightmode-secondary transition-colors hover:bg-color-bg-lightmode-secondary hover:text-color-text-lightmode-primary dark:text-color-text-darkmode-secondary dark:hover:bg-color-bg-darkmode-secondary dark:hover:text-color-text-darkmode-primary"
              aria-label="Close modal"
              type="button"
            >
              <CloseIcon className="h-5 w-5" />
            </button>
          </div>

          <div className="px-4 py-5 text-center">
            <div className="mb-8 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              Watch this 3-minute demo to get started with Bounce.
            </div>

            <div className="mb-8 overflow-hidden rounded-lg">
              <iframe
                id="demo-video"
                className="aspect-video w-full"
                src="https://www.youtube.com/embed/nRXO7vTx-rw?enablejsapi=1"
                title="Bounce Welcome Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <Button
              type="button"
              variant="primary"
              size="lg"
              className="w-full"
              onClick={() => {
                setIsVideoStep(false);
                trackEvent(ONBOARDING_DEMO_WATCHED, {
                  source: 'quick_onboard_modal',
                });
              }}
              isInlineDesktop
            >
              Continue Setup
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={isDisabled ? () => {} : closeAndResetQuery}
      classNameMaxWidth="max-w-4xl"
    >
      <form
        className="relative flex max-h-[96vh] flex-col"
        onSubmit={handleSubmit(
          async (data) => {
            const numberFormatted = toDigits(data.phoneNumber || '')?.slice(
              data.phoneCountryCode?.length,
            );

            await updateCoachSettingsQuickMutation({
              variables: {
                userId: viewer?.userId || '',
                defaultSport: data.defaultSport as SportsEnum,
                profileImageFileName: data.profilePhoto.profileImageFileName,
                profileImagePath: data.profilePhoto.profileImagePath,
                profileImageProvider: data.profilePhoto.profileImageProvider,
                profileImageProviderId: data.profilePhoto.profileImageProviderId,
                profileImageProviderUrl: data.profilePhoto.profileImageProviderUrl,
                profileImageUrl: data.profilePhoto.profileImageUrl,
                priceUnitAmountRemoteCoachDefault:
                  convertFormattedPriceToUnitAmount(data.priceUnitAmountRemoteCoachDefault || 0) ||
                  null,
                priceUnitAmountCoachDefault:
                  convertFormattedPriceToUnitAmount(data.priceUnitAmountCoachDefault || 0) || null,
                gender: data.gender as GenderEnum,
                phoneNumber: numberFormatted,
                phoneCountryCode: data.phoneCountryCode,
              },
            });
            closeAndResetQuery();
          },
          (errors) => {
            if (errors && Object.keys(errors).length === 1 && errors.phoneNumber) {
              phoneRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          },
        )}
      >
        <div className="flex items-center justify-between border-b border-color-border-input-lightmode px-4 py-5 dark:border-color-border-input-darkmode md:px-10">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setIsVideoStep(true)}
              type="button"
              className="rounded-full p-2 text-color-text-lightmode-secondary transition-colors hover:bg-color-bg-lightmode-secondary hover:text-color-text-lightmode-primary dark:text-color-text-darkmode-secondary dark:hover:bg-color-bg-darkmode-secondary dark:hover:text-color-text-darkmode-primary"
              aria-label="Back to demo"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <h3 className="text-xl font-bold text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              Get Started
            </h3>
          </div>
          <button
            onClick={() => closeAndResetQuery()}
            className="rounded-full p-2 text-color-text-lightmode-secondary transition-colors hover:bg-color-bg-lightmode-secondary hover:text-color-text-lightmode-primary dark:text-color-text-darkmode-secondary dark:hover:bg-color-bg-darkmode-secondary dark:hover:text-color-text-darkmode-primary"
            aria-label="Close modal"
            type="button"
          >
            <CloseIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="divide-color-border-lightmode-secondary dark:divide-color-border-darkmode-secondary flex flex-col divide-y overflow-y-auto px-4 md:px-10">
          <div className="py-6">
            <div className="flex w-full flex-col gap-2 md:flex-row">
              <div className="mb-2 w-[240px] shrink-0 font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Profile Photo
              </div>
              <div className="flex w-full gap-x-4" ref={photoRef}>
                <Controller
                  control={control}
                  name="profilePhoto"
                  render={({ field: { onChange } }) => (
                    <Dropzone
                      classNameWrapper="w-full flex flex-col md:flex-row-reverse gap-4"
                      classNameImageContainer="flex aspect-square h-[104px] max-h-[104px] max-w-[104px]"
                      styles="gap-2 rounded-md"
                      multiple={false}
                      isImage={true}
                      uploadedFileUrl={getValues('profilePhoto')?.profileImageProviderUrl || ''}
                      onSelectFiles={async (files) => {
                        try {
                          setImageLoading();
                          const file = files[0];
                          const response = await uploadSignedFile({ file });

                          if (response) {
                            onChange({
                              profileImageFileName: response.fileName,
                              profileImagePath: response.path,
                              profileImageProvider: MediaProviders.Cloudflare,
                              profileImageProviderId: response.fileName.split('.')[0],
                              profileImageProviderUrl: response.providerUrl,
                              profileImageUrl: response.url,
                            });
                          }

                          setImageSuccess();
                        } catch (error) {
                          setImageError('');
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <p className="bottom-1 mt-2 px-4 text-xs text-color-error sm:mt-0 md:px-10">
              {errors?.profilePhoto && errors?.profilePhoto?.profileImageProviderUrl?.message}
            </p>
          </div>

          <div className="flex w-full items-center gap-4 py-6" ref={sportRef}>
            <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
              <div className="w-[240px] shrink-0 font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Sport
              </div>
              <RadioList
                listHeader=""
                control={control}
                errors={errors}
                name={`defaultSport`}
                options={[
                  { value: SportsEnum.Pickleball, label: 'Pickleball' },
                  { value: SportsEnum.Tennis, label: 'Tennis' },
                ]}
                isHideOther
                groupClassName="flex items-center gap-6"
              />
            </div>
          </div>
          <div className="flex w-full items-center gap-4 py-6">
            <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
              <div className="w-[240px] shrink-0 font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Gender
              </div>
              <RadioList
                listHeader=""
                control={control}
                errors={errors}
                name={`gender`}
                options={[
                  { value: GenderEnum.Male, label: 'Male' },
                  { value: GenderEnum.Female, label: 'Female' },
                ]}
                isHideOther
                groupClassName="flex items-center gap-6"
              />
            </div>
          </div>

          <div className="flex w-full items-center gap-4 py-6">
            <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
              <div className="w-[240px] shrink-0">
                <Switcher
                  errors={errors}
                  control={control}
                  name={`remoteCoaching`}
                  label="Remote Coaching"
                  classNameLabel="text-nowrap"
                />
              </div>

              {!!getValues('remoteCoaching') && (
                <div className="grow">
                  <InputField
                    renderIcon={() => '$'}
                    inputProps={{
                      type: 'number',
                      defaultValue: getValues('priceUnitAmountRemoteCoachDefault'),
                    }}
                    fieldLabel=""
                    errors={errors}
                    placeholder="Remote coaching price"
                    name={`priceUnitAmountRemoteCoachDefault`}
                    register={register}
                    inputType={InputType.RegularWithIcon}
                    errorBorder={true}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full items-center gap-4 py-6">
            <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
              <div className="w-[240px] shrink-0">
                <Switcher
                  errors={errors}
                  control={control}
                  name={`onCourtCoaching`}
                  label="On-court lessons"
                  classNameLabel="text-nowrap"
                />
              </div>

              {!!getValues('onCourtCoaching') && (
                <div className="grow">
                  <InputField
                    renderIcon={() => '$'}
                    inputProps={{
                      type: 'number',
                      defaultValue: getValues('priceUnitAmountCoachDefault'),
                    }}
                    fieldLabel=""
                    errors={errors}
                    placeholder="On-court hourly price"
                    name={`priceUnitAmountCoachDefault`}
                    register={register}
                    inputType={InputType.RegularWithIcon}
                    errorBorder={true}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full items-center gap-4 py-6" ref={phoneRef}>
            <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
              <div className="w-[240px] text-nowrap font-bold text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Phone Number
              </div>
              <InputField
                fieldLabel=""
                errors={errors}
                placeholder="Enter your phone number"
                name="phoneNumber"
                register={register}
                inputType={InputType.PhoneInput}
                setDialCode={(code: string) => setValue('phoneCountryCode', code)}
                control={control}
                isPhoneGrow
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end border-t border-color-border-input-lightmode px-4 py-5 dark:border-color-border-input-darkmode md:px-10">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="w-full"
            disabled={isDisabled}
            isInlineDesktop
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
