import React, { useEffect, useRef } from 'react';
import { FeedbackSentimentEnum } from 'types/generated/client';
import CloseIcon from 'svg/CloseIcon';
import { ButtonText } from 'components/Button';
import Modal from 'components/modals/Modal';
import classNames from 'styles/utils/classNames';
import SkillSentimentCard from './SkillSentimentCard';
import { SkillSentimentsModalProps } from './props';
import { getSkillLabel } from './utils';

const SkillSentimentsModal: React.FC<SkillSentimentsModalProps> = ({
  isOpen,
  handleClose,
  lessonSentimentDetails,
  anchorSentiment,
}) => {
  const positiveRef = useRef<HTMLDivElement>(null);
  const neutralRef = useRef<HTMLDivElement>(null);
  const criticalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && lessonSentimentDetails && anchorSentiment) {
      const timeoutId = setTimeout(() => {
        if (
          anchorSentiment === FeedbackSentimentEnum.Positive &&
          lessonSentimentDetails?.positive.length > 0
        ) {
          positiveRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (
          anchorSentiment === FeedbackSentimentEnum.Neutral &&
          lessonSentimentDetails?.neutral.length > 0
        ) {
          neutralRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (
          anchorSentiment === FeedbackSentimentEnum.Critical &&
          lessonSentimentDetails?.critical.length > 0
        ) {
          criticalRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, anchorSentiment, lessonSentimentDetails]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} classNameMaxWidth="max-w-[60%] w-full">
      <div className="flex items-center justify-between border-b px-8 py-4">
        <span className="typography-product-heading-desktop">
          {getSkillLabel(lessonSentimentDetails?.skill)}
        </span>
        <ButtonText onClick={handleClose}>
          <CloseIcon className="h-5 w-5" />
        </ButtonText>
      </div>
      <div className="mx-auto max-w-main-content-container px-8 pb-10 pt-6">
        <div ref={positiveRef} className="flex flex-col gap-6">
          {lessonSentimentDetails?.positive.map((lessonSentimentDetail, index) => (
            <SkillSentimentCard
              key={index}
              date={lessonSentimentDetail.date}
              media={lessonSentimentDetail.media}
              sentiment={FeedbackSentimentEnum.Positive}
              detail={lessonSentimentDetail.detail}
            />
          ))}
        </div>
        <hr
          className={classNames(
            'my-12',
            (lessonSentimentDetails!?.positive.length === 0 ||
              lessonSentimentDetails!?.neutral.length === 0) &&
              'hidden',
          )}
        />
        <div ref={neutralRef} className="flex flex-col gap-6">
          {lessonSentimentDetails?.neutral.map((lessonSentimentDetail, index) => (
            <SkillSentimentCard
              key={index}
              date={lessonSentimentDetail.date}
              media={lessonSentimentDetail.media}
              sentiment={FeedbackSentimentEnum.Neutral}
              detail={lessonSentimentDetail.detail}
            />
          ))}
        </div>
        <hr
          className={classNames(
            'my-12',
            lessonSentimentDetails?.neutral.length === 0 &&
              lessonSentimentDetails?.critical.length === 0 &&
              'hidden',
          )}
        />
        <div ref={criticalRef} className="flex flex-col gap-6">
          {lessonSentimentDetails?.critical.map((lessonSentimentDetail, index) => (
            <SkillSentimentCard
              key={index}
              date={lessonSentimentDetail.date}
              media={lessonSentimentDetail.media}
              sentiment={FeedbackSentimentEnum.Critical}
              detail={lessonSentimentDetail.detail}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SkillSentimentsModal;
