import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Star from 'svg/Star';
import { RatingProps } from './props';
import { StarFill } from './styles';

export default function CustomStarRating({
  fillColorClassName,
  unfillColorClassName,
  starClassName,
  textClassName = 'text-gray-600',
  gapBetweenStars = '2',
  starCount = 5,
  labels,
  onRatingChange,
  initialRating = 0,
  readOnly = false,
}: RatingProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedRating, setSelectedRating] = useState<number>(0);

  useEffect(() => {
    setSelectedRating(initialRating);
  }, [initialRating]);

  const handleClick = (index: number) => {
    if (!readOnly) {
      const newRating = index + 1;
      setSelectedRating(newRating);
      if (onRatingChange) {
        onRatingChange(newRating);
      }
    }
  };

  const getStarFillPercentage = (index: number) => {
    if (hoveredIndex !== null) {
      return index <= hoveredIndex ? 100 : 0;
    }
    if (index < Math.floor(selectedRating)) {
      return 100;
    }
    if (index === Math.floor(selectedRating)) {
      return (selectedRating % 1) * 100;
    }
    return 0;
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames(`flex`, `space-x-${gapBetweenStars}`)}
        onMouseLeave={() => !readOnly && setHoveredIndex(null)}
      >
        {Array.from({ length: starCount }).map((_, index) => (
          <div
            key={index}
            className="flex flex-col items-center gap-1.5"
            onMouseEnter={() => !readOnly && setHoveredIndex(index)}
            onClick={() => handleClick(index)}
          >
            {/* Star */}
            <div className="relative">
              <Star className={classNames(starClassName, unfillColorClassName)} />
              <StarFill
                readOnly={readOnly}
                fillPercentage={getStarFillPercentage(index)}
                index={index}
              >
                <Star className={classNames(starClassName, fillColorClassName)} />
              </StarFill>
            </div>

            {labels && labels[index] && (
              <span className={classNames(textClassName)}>{labels[index]}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
