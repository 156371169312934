import * as React from 'react';

export default function Group({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 23 19'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2252 4.19961C15.2252 6.18783 13.6134 7.79961 11.6252 7.79961C9.63696 7.79961 8.02519 6.18783 8.02519 4.19961C8.02519 2.21138 9.63696 0.599609 11.6252 0.599609C13.6134 0.599609 15.2252 2.21138 15.2252 4.19961Z"
        fill="currentColor"
      />
      <path
        d="M21.2252 6.59961C21.2252 7.92509 20.1507 8.99961 18.8252 8.99961C17.4997 8.99961 16.4252 7.92509 16.4252 6.59961C16.4252 5.27413 17.4997 4.19961 18.8252 4.19961C20.1507 4.19961 21.2252 5.27413 21.2252 6.59961Z"
        fill="currentColor"
      />
      <path
        d="M16.4252 14.9996C16.4252 12.3486 14.2762 10.1996 11.6252 10.1996C8.97422 10.1996 6.82519 12.3486 6.82519 14.9996V18.5996H16.4252V14.9996Z"
        fill="currentColor"
      />
      <path
        d="M6.82519 6.59961C6.82519 7.92509 5.75067 8.99961 4.42519 8.99961C3.09971 8.99961 2.02519 7.92509 2.02519 6.59961C2.02519 5.27413 3.09971 4.19961 4.42519 4.19961C5.75067 4.19961 6.82519 5.27413 6.82519 6.59961Z"
        fill="currentColor"
      />
      <path
        d="M18.8252 18.5996V14.9996C18.8252 13.7346 18.499 12.5459 17.9261 11.5128C18.2135 11.4389 18.5147 11.3996 18.8252 11.3996C20.8134 11.3996 22.4252 13.0114 22.4252 14.9996V18.5996H18.8252Z"
        fill="currentColor"
      />
      <path
        d="M5.32431 11.5128C4.75141 12.5459 4.42519 13.7346 4.42519 14.9996V18.5996H0.825195V14.9996C0.825195 13.0114 2.43697 11.3996 4.42519 11.3996C4.73564 11.3996 5.03692 11.4389 5.32431 11.5128Z"
        fill="currentColor"
      />
    </svg>
  );
}
