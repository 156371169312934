import Skeleton from 'components/LoadingSkeleton';

const PlayerDetailsSkeleton = () => {
  return (
    <div className="mx-auto flex w-full max-w-[51rem] flex-col gap-8 px-4 py-ds-xl">
      {/* Profile Section */}
      <div className="flex items-center space-x-4">
        <Skeleton circle={true} height={50} width={50} />
        <div>
          <Skeleton width={150} height={20} />
          <Skeleton width={100} height={15} className="mt-2" />
        </div>
      </div>

      {/* Video Analysis & Pending Count */}
      <div className="grid grid-cols-2 gap-4">
        <Skeleton width="100%" height={80} />
        <Skeleton width="100%" height={80} />
      </div>

      {/* Skills Analyzed Table */}
      <div className="space-y-2">
        <Skeleton width={150} height={20} />
        <Skeleton width="100%" height={40} />
        <Skeleton width="100%" height={40} />
        <Skeleton width="100%" height={40} />
      </div>

      {/* Date Section */}
      <div className="flex items-center space-x-2">
        <Skeleton width={24} height={24} />
        <Skeleton width={120} height={20} />
      </div>

      {/* Video Section */}
      <Skeleton width={80} height={30} />
      <Skeleton width="100%" height={250} />

      {/* Button */}
      <div className="flex justify-end">
        <Skeleton width={150} height={40} />
      </div>
    </div>
  );
};

export default PlayerDetailsSkeleton;
