import { differenceInHours, differenceInMinutes } from 'date-fns';
import { capitalize } from 'lodash';
import {
  FeedbackSentimentEnum,
  FollowStatusesEnum,
  GetCoachReflectionsBySubmitterIdQuery,
  GetCoachVideoAnalysisBySubmitterIdQuery,
  GetMyCoachFollowersPendingVideosQuery,
  GetPlayerReflectionByPlayerIdQuery,
  LessonReflections,
  LessonSkillsEnum,
  VideoAnalysis,
  VideoAnalysisReviewStatusesEnum,
} from 'types/generated/client';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import Pill from 'components/Pill';
import { ReflectionFormType } from './components/ReflectFlowModal/props';
import {
  LessonSentimentDetailsType,
  PlayerFiltersFormValues,
  PlayerType,
  SentimentInfoType,
} from './props';

export const filterPlayers = (
  followersData: PlayerType[] | undefined,
  playerFormValues: PlayerFiltersFormValues,
) => {
  if (!followersData) {
    return [];
  }

  return followersData.filter((follow) => {
    const profile = follow.followerProfile;

    if (!profile || follow.status === FollowStatusesEnum.Inactive) {
      return false;
    }

    // Filter by player search text
    if (playerFormValues.playerSearchText && playerFormValues.playerSearchText.length >= 2) {
      const isSearched =
        profile.fullName?.toUpperCase().includes(playerFormValues.playerSearchText.toUpperCase()) ||
        profile.username?.toUpperCase().includes(playerFormValues.playerSearchText.toUpperCase());

      return isSearched;
    }

    return true;
  });
};

export const mapReflectionsToTable = (
  data: LessonReflections[],
  onPillClick: (
    lessonSentimentDetails: LessonSentimentDetailsType,
    anchor: FeedbackSentimentEnum,
  ) => void,
): TableRow[] => {
  let rowIndex = 1;
  const skillMap = new Map<string, LessonSentimentDetailsType>();

  data.forEach((reflection) => {
    reflection.lessonReflectionComments.forEach((comment) => {
      const skill = comment.skill;

      if (!skill) return;
      const sentiment = comment.sentiment.toUpperCase();

      if (!skillMap.has(skill)) {
        skillMap.set(skill, {
          rowIndex: rowIndex++,
          skill: skill,
          positive: [],
          neutral: [],
          critical: [],
        });
      }

      const skillData = skillMap.get(skill);
      if (skillData) {
        const sentimentInfo: SentimentInfoType = {
          detail: comment.details || '',
          date: reflection.createdAt,
          media: {
            src: comment.lessonReflectionFiles[0]?.providerUrl || '',
            type: comment.lessonReflectionFiles[0]?.fileType || '',
            format: comment.lessonReflectionFiles[0]?.fileFormat || '',
          },
        };

        if (sentiment === FeedbackSentimentEnum.Positive) {
          skillData.positive.push(sentimentInfo);
        } else if (sentiment === FeedbackSentimentEnum.Neutral) {
          skillData.neutral.push(sentimentInfo);
        } else {
          skillData.critical.push(sentimentInfo);
        }
      }
    });
  });

  // Sort skills by the total number of activities (positive + neutral + critical)
  const sortedSkills = Array.from(skillMap.values()).sort(
    (a, b) =>
      b.positive.length +
      b.neutral.length +
      b.critical.length -
      (a.positive.length + a.neutral.length + a.critical.length),
  );

  const tableData: TableRow[] = sortedSkills.map((skillData, index) => {
    const { positive, neutral, critical, skill } = skillData;
    const lessonSentimentDetails = {
      skill,
      positive,
      neutral,
      critical,
    };

    return {
      id: index + 1, // Update row index based on the sorted order
      rawData: skillData,
      rowData: [
        <span className="typography-product-subheading">{index + 1}</span>,
        <span className="typography-product-card-title-mobile">
          {capitalize(skill.replaceAll('_', ' '))}
        </span>,
        <span className="typography-product-subheading">
          {positive.length + neutral.length + critical.length}
        </span>,
      ],
      expandableContent: (
        <div className="flex pr-24 max-sm:pr-14">
          <div className="ml-auto flex flex-col gap-4">
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Positive)}
            >
              <Pill text={positive.length.toString()} type="success" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Positive
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Neutral)}
            >
              <Pill text={neutral.length.toString()} size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Neutral
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Critical)}
            >
              <Pill text={critical.length.toString()} type="critical" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Critical
              </span>
            </div>
          </div>
        </div>
      ),
    };
  });

  return tableData;
};

export const mapVideoAnalysisToTable = (
  data: GetCoachVideoAnalysisBySubmitterIdQuery['videoAnalysis'],
  onPillClick: (
    lessonSentimentDetails: LessonSentimentDetailsType,
    anchor: FeedbackSentimentEnum,
  ) => void,
): TableRow[] => {
  let rowIndex = 1;
  const skillMap = new Map<string, LessonSentimentDetailsType>();

  data.forEach((video) => {
    video.videoAnalysisComments.forEach((comment) => {
      comment.videoAnalysisSkillsComments.forEach((skillComment) => {
        const skill = skillComment.videoLessonSkills.skill;

        if (!skill) return;
        const sentiment = comment.sentiment.toUpperCase();

        if (!skillMap.has(skill)) {
          skillMap.set(skill, {
            rowIndex: rowIndex++,
            skill: skill,
            positive: [],
            neutral: [],
            critical: [],
          });
        }

        const skillData = skillMap.get(skill);
        if (skillData) {
          if (sentiment === FeedbackSentimentEnum.Positive) {
            skillData.positive.push({
              detail: skillComment.videoAnalysisComments?.details || '',
              date: video.createdAt,
              media: {
                src: skillComment.videoAnalysisComments?.videoAnalysisFiles?.providerUrl || '',
                type: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileType || '',
                format: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileFormat || '',
              },
            });
          } else if (sentiment === FeedbackSentimentEnum.Neutral) {
            skillData.neutral.push({
              detail: skillComment.videoAnalysisComments?.details || '',
              date: video.createdAt,
              media: {
                src: skillComment.videoAnalysisComments?.videoAnalysisFiles?.providerUrl || '',
                type: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileType || '',
                format: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileFormat || '',
              },
            });
          } else {
            skillData.critical.push({
              detail: skillComment.videoAnalysisComments?.details || '',
              date: video.createdAt,
              media: {
                src: skillComment.videoAnalysisComments?.videoAnalysisFiles?.providerUrl || '',
                type: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileType || '',
                format: skillComment.videoAnalysisComments?.videoAnalysisFiles?.fileFormat || '',
              },
            });
          }
        }
      });
    });
  });

  // Sort skills by the total number of activities (positive + neutral + critical)
  const sortedSkills = Array.from(skillMap.values()).sort(
    (a, b) =>
      b.positive.length +
      b.neutral.length +
      b.critical.length -
      (a.positive.length + a.neutral.length + a.critical.length),
  );

  const tableData: TableRow[] = sortedSkills.map((skillData, index) => {
    const { positive, neutral, critical, skill } = skillData;
    const lessonSentimentDetails = {
      skill,
      positive,
      neutral,
      critical,
    };

    return {
      id: index + 1, // Update row index based on the sorted order
      rawData: skillData,
      rowData: [
        <span className="typography-product-subheading">{index + 1}</span>,
        <span className="typography-product-card-title-mobile">
          {capitalize(skill?.replaceAll('_', ' '))}
        </span>,
        <span className="typography-product-subheading">
          {positive.length + neutral.length + critical.length}
        </span>,
      ],
      expandableContent: (
        <div className="flex pr-24 max-sm:pr-14">
          <div className="ml-auto flex flex-col gap-4">
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Positive)}
            >
              <Pill text={positive.length.toString()} type="success" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Positive
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Neutral)}
            >
              <Pill text={neutral.length.toString()} size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Neutral
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-4"
              onClick={() => onPillClick(lessonSentimentDetails, FeedbackSentimentEnum.Critical)}
            >
              <Pill text={critical.length.toString()} type="critical" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Critical
              </span>
            </div>
          </div>
        </div>
      ),
    };
  });

  return tableData;
};

export const mergePendingActionsWithFollowers = (
  userFollows: PlayerType[],
  followerPendingVideos: GetMyCoachFollowersPendingVideosQuery['videoAnalysis'],
  lessonParticipants: GetMyCoachFollowersPendingVideosQuery['lessonParticipants'],
): PlayerType[] => {
  const mergedPlayers = userFollows.map((userFollow) => {
    const pendingVideoAnalysis = followerPendingVideos.find(
      (followerPendingVideo) => followerPendingVideo.submissionUserId === userFollow.followerUserId,
    );

    const pendingReflection = lessonParticipants.find(
      (lessonParticipant) =>
        lessonParticipant.userId === userFollow.followerUserId &&
        lessonParticipant.lesson.lessonReflections.length === 0,
    );

    let pendingAction = null;
    if (pendingVideoAnalysis && pendingReflection) {
      pendingAction =
        new Date(pendingVideoAnalysis.createdAt) > new Date(pendingReflection.lesson.createdAt)
          ? {
              lessonId: pendingVideoAnalysis.id,
              coachLessonType: RemoteCoachTypesEnum.Remote,
              createdAt: pendingVideoAnalysis.createdAt,
            }
          : {
              lessonId: pendingReflection.lesson.id,
              coachLessonType: RemoteCoachTypesEnum.Court,
              createdAt: pendingReflection.lesson.createdAt,
            };
    } else if (pendingVideoAnalysis) {
      pendingAction = {
        lessonId: pendingVideoAnalysis.id,
        coachLessonType: RemoteCoachTypesEnum.Remote,
        createdAt: pendingVideoAnalysis.createdAt,
      };
    } else if (pendingReflection) {
      pendingAction = {
        lessonDate: pendingReflection.lesson.createdAt,
        lessonId: pendingReflection.lesson.id,
        coachLessonType: RemoteCoachTypesEnum.Court,
        createdAt: pendingReflection.lesson.createdAt,
      };
    }

    return {
      ...userFollow,
      pendingAction,
    };
  });

  return mergedPlayers;
};

export const getTimeSpentOnCourt = (
  lessons:
    | GetCoachReflectionsBySubmitterIdQuery['lessons']
    | GetPlayerReflectionByPlayerIdQuery['lessons']
    | undefined,
): string => {
  if (!lessons || lessons.length === 0) return '0 h, 0 m';

  const totalMinutes = lessons.reduce((total, lesson) => {
    if (!lesson.times || lesson.times.length === 0) return total;

    const lessonMinutes = lesson.times.reduce((lessonTotal, time) => {
      const start = new Date(time.startDateTime);
      const end = new Date(time.endDateTime);

      if (isNaN(start.getTime()) || isNaN(end.getTime())) return lessonTotal;

      const durationMinutes = differenceInMinutes(end, start);
      return lessonTotal + durationMinutes;
    }, 0);

    return total + lessonMinutes;
  }, 0);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours} h, ${minutes} m`;
};

export const getFormattedCoachReflectionForFormToEdit = (
  lessonReflection: LessonReflections,
): ReflectionFormType => ({
  skills: lessonReflection.lessonReflectionComments.map(
    (lessonReflectionComment) =>
      lessonReflectionComment.skill || lessonReflectionComment.customSkill,
  ) as LessonSkillsEnum[],
  skillsRanking: lessonReflection?.lessonReflectionComments
    .toSorted((a, b) => a.order - b.order)
    .map(
      (sortedLessonReflectionComment) =>
        sortedLessonReflectionComment.skill || sortedLessonReflectionComment.customSkill,
    ) as LessonSkillsEnum[],
  feedback: lessonReflection.lessonReflectionComments.map((lessonReflectionComment) => ({
    sentiment: lessonReflectionComment.sentiment as FeedbackSentimentEnum,
    skill:
      (lessonReflectionComment.skill as LessonSkillsEnum) || lessonReflectionComment.customSkill,
    details: lessonReflectionComment.details || '',
    lessonReflectionFiles: lessonReflectionComment.lessonReflectionFiles?.[0],
  })),
});

export const getDifferenceInHours = (date: string) => {
  const hoursDifference = differenceInHours(new Date(), new Date(date));
  return hoursDifference;
};

export const sortVideoAnalysisData = (data: VideoAnalysis[]): VideoAnalysis[] => {
  if (!data) return [];
  return [...data].sort((a, b) => {
    const isPendingA = a.reviewStatus === VideoAnalysisReviewStatusesEnum.Pending;
    const isPendingB = b.reviewStatus === VideoAnalysisReviewStatusesEnum.Pending;

    if (isPendingA && !isPendingB) return -1; // Pending rows come first
    if (!isPendingA && isPendingB) return 1; // Non-pending rows come after
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); // Sort by date descending
  });
};
