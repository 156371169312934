export const TEST_EVENT_NAME = 'test_event';
export const COACH_WAITLIST_SUBMISSION = 'coach_waitlist_submission';
export const TOURNAMENT_CREATE_START = 'tournament_create_start';
export const TOURNAMENT_CREATE_PUBLISH = 'tournament_create_publish';
export const TOURNAMENT_CLICK_REGISTER_EXTERNAL = 'tournament_click_register_external';
export const TOURNAMENT_NAVIGATE_EXTERNAL = 'tournament_navigate_external';

// onboarding events
export const ONBOARDING_DEMO_WATCHED = 'onboarding.demo_watched';
export const ONBOARDING_EXAMPLE_REFLECTION_CLICKED = 'onboarding.example_reflection_clicked';
export const ONBOARDING_REMOTE_LESSON_WATCHED = 'onboarding.remote_lesson_watched';
export const ONBOARDING_CLIENTS_ADDED = 'onboarding.clients_added';
export const ONBOARDING_BANK_CONNECTED = 'onboarding.bank_connected';
export const ONBOARDING_PROFILE_COMPLETED = 'onboarding.profile_completed';
export const ONBOARDING_PRICING_SET = 'onboarding.pricing_set';
export const ONBOARDING_LISTING_SHARED = 'onboarding.listing_shared';
