import { snakeCase, uniqBy } from 'lodash';
import { z } from 'zod';
import { lessonSkillDisplay } from 'constants/lessonSkills';
import {
  FeedbackSentimentEnum,
  FileCategoryEnum,
  FileFormatEnum,
  LessonSkillsEnum,
} from 'types/generated/client';
import { VideoAnalysisSkillsTypeEnum } from 'utils/shared/coachBuilder';
import { toSentenceCase } from 'utils/shared/string/toSentenceCase';
import { VideoAnalysisType, VideoCommentType } from './props';

const userFilesSchema = z.object({
  fileCategory: z.enum([FileCategoryEnum.Coach, FileCategoryEnum.Other, FileCategoryEnum.Profile]),
  fileName: z.string(),
  fileType: z.string(),
  host: z.string(),
  originalFileName: z.string().nullable().optional(),
  provider: z.string(),
  providerUrl: z.string(),
  url: z.string(),
  fileFormat: z.enum([FileFormatEnum.Image, FileFormatEnum.Video, FileFormatEnum.Audio]),
  providerId: z.string(),
  userId: z.string(),
});

export const feedbackFormSchema = z.object({
  skills: z.array(z.string()).optional(),
  feedbackType: z.enum([
    FeedbackSentimentEnum.Positive,
    FeedbackSentimentEnum.Critical,
    FeedbackSentimentEnum.Neutral,
  ]),
  details: z.string().min(1, { message: 'Details are required.' }),
  videoImageFile: userFilesSchema.nullable().optional(),
});

export const getFormattedSkills = (
  videoAnalysisRequestedSkillsReviews:
    | VideoAnalysisType['videoAnalysisRequestedSkillsReviews']
    | VideoCommentType['videoAnalysisSkillsComments'] = [],
) =>
  videoAnalysisRequestedSkillsReviews.map((review) => {
    const skill = review.videoLessonSkills?.customSkill || review.videoLessonSkills?.skill;

    const skillDisplay = lessonSkillDisplay.find((display) => display.id === skill);
    if (skillDisplay) {
      return skillDisplay.displayName;
    }

    return toSentenceCase(skill?.replaceAll('_', ' ')!);
  });

export const separateSkillsByType = (skills: string[] = [], userId: string, commentId?: string) => {
  const defaultSkills = Object.values(LessonSkillsEnum) as string[];

  const mapSkill = (skill: string) => {
    const isDefaultSkill = defaultSkills.includes(skill);
    return {
      videoLessonSkills: {
        data: {
          skill: isDefaultSkill ? skill : null,
          customSkill: !isDefaultSkill ? skill : null,
          userId,
        },
      },
      ...(commentId && { videoAnalysisCommentId: commentId }),
    };
  };

  return skills.map(mapSkill);
};

export const getFeedbackFormInitialValuesFromComment = (selectedComment: VideoCommentType) => {
  const skillValues = selectedComment?.videoAnalysisSkillsComments.map(
    (skill) => skill.videoLessonSkills?.customSkill || skill.videoLessonSkills?.skill || '',
  );
  const initialValues = {
    skills: skillValues,
    feedbackType: selectedComment?.sentiment,
    details: selectedComment?.details,
    videoImageFile: selectedComment?.videoAnalysisFiles,
  };
  return initialValues;
};

export const feedbackFormDefaultValues = {
  skills: [],
  feedbackType: FeedbackSentimentEnum.Positive,
  details: '',
  videoImageFile: null,
};

export const convertSecondsToMMSS = (seconds: number): string => {
  const minutes: number = Math.floor(seconds / 60);
  const secs: number = Math.floor(seconds % 60);

  return [minutes.toString().padStart(2, '0'), secs.toString().padStart(2, '0')].join(':');
};

export const convertMMSSToSeconds = (timeString: string): number => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes * 60 + seconds;
};
