import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { lessonSkillDisplay } from 'constants/lessonSkills';
import { LessonSkillCategoriesEnum, SportsEnum } from 'types/generated/client';
import { toSentenceCase } from 'utils/shared/string/toSentenceCase';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import PlusPlain from 'svg/PlusPlain';
import Button from 'components/Button';
import Pill from 'components/Pill';
import classNames from 'styles/utils/classNames';
import AddSkillModal from './AddSkillModal';
import { SkillsProps } from './types';
import { getLessonSkills, toggleSkillSelection, transformCustomSkillToDb } from './utils';

const Skills = <T extends FieldValues>({
  control,
  label = true,
  fieldName = 'skills',
  allowCustom = true,
  labelText,
  getValues,
  setValue,
}: SkillsProps<T>) => {
  const [skillBucket, setSkillBucket] = useState<SportsEnum | LessonSkillCategoriesEnum | null>(
    null,
  );
  const { user } = useGetCurrentUser();
  const {
    isOpen: isAddSkillModalOpen,
    openModal: openAddSkillModal,
    closeModal: closeAddSkillModal,
  } = useModal();
  const lessonSkills = getLessonSkills({
    allSkills: getValues(fieldName as Path<T>) || [],
    bucket: skillBucket,
  });

  const handleAddSkill = (newSkill: string) => {
    const currentSkills = getValues(fieldName as Path<T>) || [];
    if (
      getLessonSkills({ allSkills: currentSkills, bucket: skillBucket }).allSkills.length <
      getLessonSkills({ allSkills: [...currentSkills, newSkill], bucket: skillBucket }).allSkills
        .length
    ) {
      setValue(
        fieldName as Path<T>,
        toggleSkillSelection(currentSkills, transformCustomSkillToDb(newSkill)) as PathValue<
          T,
          Path<T>
        >,
      );
    }
  };

  useEffect(() => {
    if (user) {
      if (user.isPicklrP4Member) {
        setSkillBucket(LessonSkillCategoriesEnum.P4);
      } else if (user.isQuatroPickleballMember) {
        setSkillBucket(LessonSkillCategoriesEnum.Quatro);
      } else if (user.defaultSport === SportsEnum.Tennis) {
        setSkillBucket(SportsEnum.Tennis);
      } else {
        setSkillBucket(SportsEnum.Pickleball);
      }
    }
  }, [user]);

  console.log({ skillBucket });

  return (
    <>
      <div className="">
        <div className={classNames('flex items-center', label ? 'justify-between' : 'justify-end')}>
          {label && (
            <p className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              {labelText || 'Which skill are you focused on?'}
            </p>
          )}
          <Listbox value={skillBucket} onChange={setSkillBucket}>
            {({ open }) => (
              <div className="relative z-20">
                <Listbox.Button className="focus-on-tab flex items-center p-1 text-sm font-semibold text-color-text-lightmode-primary outline-none ring-0 ring-offset-0 dark:text-color-text-darkmode-primary">
                  <span className="mr-ds-sm">{toSentenceCase(skillBucket || '')}</span>
                  <ChevronDownIcon className="h-3 w-3 text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder" />
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="focus-on-tab absolute right-0 top-6 z-20 mt-1 w-full min-w-[12rem] overflow-auto rounded-lg border border-color-border-input-lightmode bg-color-bg-lightmode-primary shadow-popover dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary">
                    {[SportsEnum.Pickleball, SportsEnum.Tennis].map((sport) => (
                      <Listbox.Option
                        key={sport}
                        value={sport}
                        className={({ active }) =>
                          classNames(
                            'group relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-color-bg-lightmode-invert hover:text-color-text-lightmode-invert hover:dark:bg-color-bg-darkmode-invert hover:dark:text-color-text-darkmode-invert',
                            active &&
                              'bg-color-bg-lightmode-invert text-color-text-lightmode-invert dark:bg-color-bg-darkmode-invert dark:text-color-text-darkmode-invert',
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate',
                                selected ? 'font-medium' : 'font-normal',
                              )}
                            >
                              {toSentenceCase(sport)}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="h-4 w-4" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                    {user?.isQuatroPickleballMember && (
                      <Listbox.Option
                        value={LessonSkillCategoriesEnum.Quatro}
                        className={({ active }) =>
                          classNames(
                            'group relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-color-bg-lightmode-invert hover:text-color-text-lightmode-invert hover:dark:bg-color-bg-darkmode-invert hover:dark:text-color-text-darkmode-invert',
                            active &&
                              'bg-color-bg-lightmode-invert text-color-text-lightmode-invert dark:bg-color-bg-darkmode-invert dark:text-color-text-darkmode-invert',
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate',
                                selected ? 'font-medium' : 'font-normal',
                              )}
                            >
                              Quatro
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="h-4 w-4" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    )}
                    {user?.isPicklrP4Member && (
                      <Listbox.Option
                        value={LessonSkillCategoriesEnum.P4}
                        className={({ active }) =>
                          classNames(
                            'group relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-color-bg-lightmode-invert hover:text-color-text-lightmode-invert hover:dark:bg-color-bg-darkmode-invert hover:dark:text-color-text-darkmode-invert',
                            active &&
                              'bg-color-bg-lightmode-invert text-color-text-lightmode-invert dark:bg-color-bg-darkmode-invert dark:text-color-text-darkmode-invert',
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate',
                                selected ? 'font-medium' : 'font-normal',
                              )}
                            >
                              P4
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="h-4 w-4" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>

        <Controller
          name={fieldName as Path<T>}
          control={control}
          render={({ field, formState: { errors } }) => (
            <div className="mt-4">
              <div className="flex flex-wrap items-start gap-2">
                {lessonSkills.allSkills.map((lessonSkill) => (
                  <Pill
                    key={lessonSkill.value}
                    text={lessonSkill.label}
                    selected={field.value?.includes(lessonSkill.value)}
                    onClick={() =>
                      field.onChange(toggleSkillSelection(field.value || [], lessonSkill.value))
                    }
                    mode="select"
                  />
                ))}

                {allowCustom && (
                  <Pill
                    text="Custom"
                    onClick={() => openAddSkillModal()}
                    mode="select"
                    icon={<PlusPlain className="h-4 w-4 scale-125" />}
                  />
                )}
              </div>

              {errors?.[fieldName]?.message && typeof errors?.[fieldName]?.message === 'string' && (
                <p className="mt-2 text-xs text-color-error">{errors?.[fieldName].message}</p>
              )}
            </div>
          )}
        />
      </div>
      <AddSkillModal
        isOpen={isAddSkillModalOpen}
        handleAddSkill={handleAddSkill}
        handleClose={() => closeAddSkillModal()}
      />
    </>
  );
};

export default Skills;
