import { useMemo, useState } from 'react';
import { FeedbackSentimentEnum } from 'types/generated/client';
import { useModal } from 'hooks/useModal';
import {
  LessonSentimentDetailsType,
  OnCourtFeedbackTableProps,
} from 'screens/CoachPlayersTab/props';
import { mapReflectionsToTable } from 'screens/CoachPlayersTab/utils';
import ExpandableTable from 'components/ExpandableTable';
import SkillSentimentsModal from './SkillSentimentsModal';

const OnCourtFeedbackTable: React.FC<OnCourtFeedbackTableProps> = ({
  lessonReflections,
  className,
}) => {
  const [selectedSkillForDetail, setSelectedSkillForDetail] = useState<{
    lessonSentimentDetails: LessonSentimentDetailsType;
    anchorSentiment: FeedbackSentimentEnum | null;
  } | null>(null);

  const sentimentDetailModal = useModal();

  const headers = [
    { label: '#' },
    { label: 'Skill', headerWidthClassName: 'w-[60%]' },
    { label: 'Activities' },
  ];

  const onSentimentClick = (
    lessonSentimentDetails: LessonSentimentDetailsType,
    anchorSentiment: FeedbackSentimentEnum,
  ) => {
    setSelectedSkillForDetail({ lessonSentimentDetails, anchorSentiment });
    sentimentDetailModal.openModal();
  };

  const rows = useMemo(
    () => (lessonReflections ? mapReflectionsToTable(lessonReflections, onSentimentClick) : []),
    [lessonReflections],
  );

  return (
    <>
      <div className={className}>
        <h4 className="typography-product-heading-compact-desktop mb-4 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Skills Trained
        </h4>
        <ExpandableTable
          labelClassName="typography-product-chips-filters text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary"
          rows={rows}
          headers={headers}
          expandedRowClassName="text-color-text-brand"
          onRowClick={(rawData) => {
            if (rawData) {
              setSelectedSkillForDetail({
                lessonSentimentDetails: rawData,
                anchorSentiment: null,
              });
              sentimentDetailModal.openModal();
            }
          }}
        />
      </div>
      <SkillSentimentsModal
        isOpen={sentimentDetailModal.isOpen}
        handleClose={sentimentDetailModal.closeModal}
        lessonSentimentDetails={selectedSkillForDetail?.lessonSentimentDetails}
        anchorSentiment={selectedSkillForDetail?.anchorSentiment}
      />
    </>
  );
};

export default OnCourtFeedbackTable;
