import { useState } from 'react';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import toast from 'react-hot-toast';
import { trackEvent } from 'services/client/analytics';
import { ONBOARDING_CLIENTS_ADDED } from 'services/client/analytics/events';
import { Button } from 'components/Button/Button';
import Modal from 'components/modals/Modal';

export interface PlayerRow {
  id: string;
  fullName: string;
  email: string;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (players: PlayerRow[]) => Promise<void>;
  isLoading?: boolean;
}

export default function BulkAddPlayersModal({ isOpen, handleClose, onSubmit, isLoading }: Props) {
  const [players, setPlayers] = useState<PlayerRow[]>([{ id: '1', fullName: '', email: '' }]);
  const [fileName, setFileName] = useState<string>('');

  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;
    if (!file.name.endsWith('.csv')) {
      toast.error('Please upload a CSV file');
      return;
    }

    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const text = e.target?.result as string;
        const lines = text.split('\n').filter((line) => line.trim());

        if (lines.length === 0) {
          throw new Error('CSV file is empty');
        }

        // Check if first line contains @ to determine if it has headers
        const hasHeaders = !lines[0].includes('@');
        const dataLines = hasHeaders ? lines.slice(1) : lines;

        const newPlayers = dataLines.map((line, index) => {
          const [fullName, email] = line.split(',').map((field) => field.trim());

          if (!email?.includes('@')) {
            throw new Error('Invalid email format - emails must contain @');
          }

          return {
            id: (index + 1).toString(),
            fullName: fullName || '',
            email: email || '',
          };
        });

        if (newPlayers.length === 0) {
          throw new Error('No valid data found in CSV');
        }

        setPlayers(newPlayers);
        toast.success(`Successfully loaded ${newPlayers.length} players`);
        trackEvent(ONBOARDING_CLIENTS_ADDED, {
          count: newPlayers.length,
          method: 'csv_upload',
          fileName: file.name,
          fileSize: file.size,
        });
      } catch (err: unknown) {
        if (err instanceof Error) {
          toast.error(err.message);
        } else {
          toast.error('Error processing CSV file');
        }
        setPlayers([{ id: '1', fullName: '', email: '' }]);
      }
    };

    reader.onerror = () => {
      toast.error('Error reading file');
      setPlayers([{ id: '1', fullName: '', email: '' }]);
    };

    reader.readAsText(file);
  };

  const resetFile = () => {
    setFileName('');
    setPlayers([{ id: '1', fullName: '', email: '' }]);
  };

  const handleSubmit = async () => {
    trackEvent(ONBOARDING_CLIENTS_ADDED, {
      count: players.length,
      method: fileName ? 'csv_upload' : 'manual',
    });

    await onSubmit(players);
    setPlayers([{ id: '1', fullName: '', email: '' }]);
    handleClose();
  };

  const addNewPlayer = () => {
    setPlayers([
      ...players,
      {
        id: (players.length + 1).toString(),
        fullName: '',
        email: '',
      },
    ]);
  };

  const updatePlayer = (id: string, field: keyof PlayerRow, value: string) => {
    setPlayers(players.map((p) => (p.id === id ? { ...p, [field]: value } : p)));
  };

  const removePlayer = (id: string) => {
    if (players.length > 1) {
      setPlayers(players.filter((p) => p.id !== id));
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="relative p-6">
        <h2 className="mb-4 text-xl font-semibold">Add Clients</h2>

        {/* CSV Upload Section */}
        <div className="mb-6 rounded-lg border-2 border-dashed border-gray-300 p-4">
          <div className="text-center">
            <p className="mb-2 text-sm font-medium">Upload CSV File</p>
            <div className="mb-4 space-y-2 text-sm text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              <p>Your CSV file should have these exact column headers:</p>
              <p className="font-mono">
                Column A: <span className="font-semibold">"Full Name"</span>
                <br />
                Column B: <span className="font-semibold">"Email"</span>
              </p>
              <p className="text-xs italic">Example row: John Smith, john@email.com</p>
              {/* <p className="text-xs italic">First row must be: Full Name, Email</p> */}
            </div>

            {fileName ? (
              <div className="mb-4 flex items-center justify-center gap-2">
                <span className="text-sm text-gray-600">{fileName}</span>
                <button
                  onClick={resetFile}
                  className="rounded-full p-1 text-gray-500 hover:bg-gray-100"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            ) : (
              <input
                type="file"
                accept=".csv"
                onChange={handleCSVUpload}
                className="hidden"
                id="csv-upload"
              />
            )}

            {!fileName && (
              <label
                htmlFor="csv-upload"
                className="inline-flex cursor-pointer items-center gap-2 rounded-md bg-gray-50 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
              >
                <PlusIcon className="h-4 w-4" />
                Choose CSV File
              </label>
            )}
          </div>
        </div>

        <div className="space-y-4">
          {players.map((player) => (
            <div key={player.id} className="space-y-2">
              <input
                className="input-form w-full"
                value={player.fullName}
                onChange={(e) => updatePlayer(player.id, 'fullName', e.target.value)}
                placeholder="Full Name"
                type="text"
              />
              <div className="flex gap-2">
                <input
                  className="input-form w-full"
                  value={player.email}
                  onChange={(e) => updatePlayer(player.id, 'email', e.target.value)}
                  placeholder="Enter email"
                  type="email"
                />
                {players.length > 1 && (
                  <button
                    onClick={() => removePlayer(player.id)}
                    className="rounded-full p-2 text-gray-500 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={addNewPlayer}
          className="mt-4 flex w-full items-center justify-center gap-2 rounded-md border border-gray-300 p-2 text-sm text-gray-600 hover:bg-gray-50"
        >
          <PlusIcon className="h-4 w-4" />
          Add Another Player
        </button>

        <div className="mt-4">
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            variant="primary"
            size="lg"
            className="w-full"
          >
            {isLoading ? 'Loading...' : 'Add'}
          </Button>
        </div>

        <button
          type="button"
          onClick={handleClose}
          className="absolute right-2 top-2 rounded-full p-2 transition-colors hover:bg-color-bg-lightmode-secondary dark:hover:bg-color-bg-darkmode-secondary"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
}
