import React, { useMemo } from 'react';
import { VideoAnalysis } from 'types/generated/client';
import { RemoteDetailsProps } from 'screens/CoachPlayersTab/props';
import { sortVideoAnalysisData } from 'screens/CoachPlayersTab/utils';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import RemoteFeedbackTable from 'components/SkillAnalysis/RemoteFeedbackTable';
import classNames from 'styles/utils/classNames';
import Stats from './Stats';
import VideoAnalysisTable from './VideoAnalysisTable';

const RemoteDetails: React.FC<RemoteDetailsProps> = ({ data, visible }) => {
  const totalVideosCount = data?.videoAnalysisAggregate.aggregate?.count || 0;

  const pendingVideosCount = data?.pendingVideoAnalysisCount.aggregate?.count || 0;

  const videos = data?.videoAnalysis || [];

  const sortedVideos = useMemo(
    () => sortVideoAnalysisData(data?.videoAnalysis as VideoAnalysis[]),
    [data?.videoAnalysis],
  );

  return (
    <div className={classNames('mt-10 hidden', visible && '!block')}>
      <div className="flex flex-col">
        <Stats videosCount={totalVideosCount} pendingCount={pendingVideosCount} />
        {videos.length > 0 ? (
          <>
            <RemoteFeedbackTable className="mt-11" videos={videos || []} />
            <hr className="my-11" />
            <VideoAnalysisTable data={sortedVideos} />
            {/* <VideosSection videos={videos || []} /> */}
          </>
        ) : (
          <NoDataPlaceholder text="No Videos" className="mt-11" />
        )}
      </div>
    </div>
  );
};

export default RemoteDetails;
